<!--
  Copyright 2024 Dataport. All rights reserved. Developed as part of the MERLOT project.

  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
-->

<div style="text-align: center;">
    <div class="qr-code" *ngIf="presentationState == ''">
        <qrcode 
            [cssClass]="'demoBorder'"
            [margin]="4"
            [imageSrc]="'./assets/merlot-pcm-opaque.png'"
            [imageHeight]="150"
            [imageWidth]="150"
            [qrdata]="invitationUrl" 
            [width]="512" 
            [errorCorrectionLevel]="'M'">
        </qrcode>
    </div>
    <h1 *ngIf="presentationState != 'done' && presentationState != ''">{{presentationState}}</h1>
</div>

<div class="diploma-container" *ngIf="presentationState == 'done'">
    <div class="diploma">
        <div class="wavy-border"></div>
        <div style="width: 50%; margin-left: 25%;">
            <h1 class="banner">Moodle University</h1>
        </div>
        <p>On the recommendation of the faculty and by the virtue of the authority vested in them the trustees of the
            university have conferred on</p>
        <h2>{{diplomaData.user_id}}</h2>
        <p>The Degree of <strong>Moodlis Doctor</strong></p>
        <p><strong>Course Name:</strong> {{diplomaData.course_name}}</p>
        <p><strong>Grade:</strong> {{diplomaData.grade}}</p>
        <div class="wax-seal"><img src="assets/seal.png" alt="" srcset=""></div>
    </div>
</div>